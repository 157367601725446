import * as i0 from '@angular/core';
import { VERSION, Injectable, Pipe, NO_ERRORS_SCHEMA, Directive, Input, HostBinding } from '@angular/core';
import { Subject, of, throwError, ReplaySubject, takeUntil, tap } from 'rxjs';
import { WMLMotionUIProperty, SingletonError, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { TranslatePipe } from '@ngx-translate/core';
import { provideHttpClient, withInterceptorsFromDi, HttpHandler, HttpResponse } from '@angular/common/http';
import { TestBed } from '@angular/core/testing';
import { RouterTestingModule } from '@angular/router/testing';
import { __decorate } from 'tslib';
let addCustomComponent = (vcf, cpnt, props) => {
  let ref = vcf.createComponent(cpnt);
  if (parseInt(VERSION.major) === 14 && parseInt(VERSION.minor) >= 1 && parseInt(VERSION.patch) >= 0 || parseInt(VERSION.major) >= 15) {
    ref.setInput('props', props);
  } else {
    ref.instance.props = props;
    ref.instance.ngOnInit?.();
  }
  return ref;
};
class WMLAngularMotionUIProperty extends WMLMotionUIProperty {
  constructor(props = {}) {
    super({
      motionEndEvent: new Subject(),
      motionKeyFrameEvent: new Subject(),
      ...props
    });
  }
}
class WMLAngularCustomComponent {
  constructor(params = {}) {
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, {
      ...Object.fromEntries(origParams)
    });
  }
}
class WMLAngularModuleForRootProps {
  constructor(props = {}) {
    this.ngxTranslateLoaderFactory = () => new WMLNGXTranslateLoader();
    Object.assign(this, {
      ...props
    });
  }
}
class WMLNGXTranslateLoader {
  constructor(i18nLocation = (lang = "en") => undefined) {
    this.i18nLocation = i18nLocation;
  }
  getTranslation(lang) {
    return of(this.i18nLocation(lang));
  }
}
class WMLNGXTranslatePipe extends TranslatePipe {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵWMLNGXTranslatePipe_BaseFactory;
      return function WMLNGXTranslatePipe_Factory(__ngFactoryType__) {
        return (ɵWMLNGXTranslatePipe_BaseFactory || (ɵWMLNGXTranslatePipe_BaseFactory = i0.ɵɵgetInheritedFactory(WMLNGXTranslatePipe)))(__ngFactoryType__ || WMLNGXTranslatePipe);
      };
    })();
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "translate",
      type: WMLNGXTranslatePipe,
      pure: false,
      standalone: true
    });
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WMLNGXTranslatePipe,
      factory: WMLNGXTranslatePipe.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WMLNGXTranslatePipe, [{
    type: Injectable
  }, {
    type: Pipe,
    args: [{
      standalone: true,
      name: 'translate',
      pure: false // required to update the value when the promise is resolved
    }]
  }], null, null);
})();
class WMLNGXTranslateMockPipe {
  constructor() {
    this.transform = myStr => {
      return myStr;
    };
  }
  static {
    this.ɵfac = function WMLNGXTranslateMockPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WMLNGXTranslateMockPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "translate",
      type: WMLNGXTranslateMockPipe,
      pure: false,
      standalone: true
    });
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WMLNGXTranslateMockPipe,
      factory: WMLNGXTranslateMockPipe.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WMLNGXTranslateMockPipe, [{
    type: Injectable
  }, {
    type: Pipe,
    args: [{
      standalone: true,
      name: 'translate',
      pure: false // required to update the value when the promise is resolved
    }]
  }], null, null);
})();

// angular
// i18n
class WMLAngularTestUtils {
  constructor(props = {}) {
    this.mock = {};
    this.mockImports = [];
    this.mockProviders = [];
    this.mockDeclarations = [];
    this.configureTestingModuleForComponents = async (targetCpnt, routes = []) => {
      if (routes.length !== 0) {
        this.mockImports.push(RouterTestingModule.withRoutes(routes));
      }
      await TestBed.configureTestingModule({
        imports: this.mockImports,
        declarations: [targetCpnt, ...this.mockDeclarations],
        providers: this.mockProviders,
        schemas: [NO_ERRORS_SCHEMA]
      }).compileComponents();
    };
    this.configureTestingModuleForStandaloneComponents = async (targetCpnt, routes = []) => {
      this.mockImports.push(targetCpnt);
      if (routes.length !== 0) {
        this.mockImports.push(RouterTestingModule.withRoutes(routes));
      }
      await TestBed.configureTestingModule({
        imports: this.mockImports,
        declarations: [...this.mockDeclarations],
        providers: [...this.mockProviders, targetCpnt],
        schemas: [NO_ERRORS_SCHEMA]
      }).compileComponents();
    };
    this.configureTestingModuleForServices = targetService => {
      TestBed.configureTestingModule({
        imports: [],
        providers: [provideHttpClient(withInterceptorsFromDi())]
      });
      let service = TestBed.inject(targetService);
      return service;
    };
    /**
     * @ experimental
    */
    // configureTestingModuleForDirectives = (
    //   targetDirective:Type<any>,
    //   myProps:Function,
    // )=>{
    //   @Component({
    //     selector:"app-my-test",
    //     template:`
    //     <div class='myTestCpnt' [scrollBottomPagination]="props">
    //     `
    //   })
    //   class TestComponent {
    //     props=myProps()
    //     @ViewChild(targetDirective) myDirective;
    //   }
    //   TestBed.configureTestingModule({
    //     declarations:[
    //       targetDirective,TestComponent
    //     ],
    //     imports:[
    //       HttpClientModule
    //     ],
    //     providers:this.mockProviders
    //   })
    //   let  { fixture,  cpnt } = this.grabComponentInstance(TestComponent)
    //   fixture.detectChanges();
    //   return { fixture,  cpnt,directive:cpnt.myDirective }
    // }
    this.grabComponentInstance = targetCpnt => {
      let fixture = TestBed.createComponent(targetCpnt);
      let cpnt = fixture.componentInstance;
      if (cpnt.hasOwnProperty("renderer2")) {
        cpnt.renderer2 = this.mock.mockRenderer2;
      }
      return {
        fixture,
        cpnt
      };
    };
    this.checkForSingleton();
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, {
      ...Object.fromEntries(origProps)
    });
  }
  static {
    this.isInit = false;
  }
  checkForSingleton() {
    if (WMLAngularTestUtils.isInit) {
      throw new SingletonError();
    } else {
      WMLAngularTestUtils.isInit = true;
    }
  }
  // @ts-ignore
  spyOnForES6Imports(target, prop) {
    // @ts-ignore
    const spy = jasmine.createSpy(`${String(prop)}Spy`);
    // @ts-ignore
    spyOnProperty(target, prop).and.returnValue(spy);
    return spy;
  }
}
let createBasicObservableError = (err = new Error()) => {
  return throwError(() => err);
};
class WMLAngularTestHttpHandler extends HttpHandler {
  constructor(props = {}) {
    super();
    this.successHandle$ = (resp = new HttpResponse()) => of(resp);
    this.errorHandle$ = (resp = new HttpResponse()) => throwError(() => resp);
    this.handle$ = this.successHandle$();
    this.updateHandle = handle$ => {
      this.handle$ = handle$;
    };
    this.handle = () => {
      return this.handle$;
    };
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, {
      ...Object.fromEntries(origProps)
    });
  }
}
function NotifyOnChange() {
  return function (target, propertyKey) {
    const privateKey = `_${String(propertyKey)}`;
    Object.defineProperty(target, privateKey, {
      writable: true,
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(target, propertyKey, {
      get: function () {
        return this[privateKey];
      },
      set: function (newValue) {
        const oldValue = this[privateKey];
        this[privateKey] = newValue;
        if (oldValue !== newValue) {
          this.listenForSetStateSub?.unsubscribe();
          this.listenForSetStateSub = this.listenForSetState()?.subscribe();
        }
      },
      enumerable: true,
      configurable: true
    });
  };
}
// be very careful modifying this function as it can make typescript very angry
// TODO turn to a decorator or whatever you can for typescript to see the types coming from the  base class
function WMLComponentBaseZeroPropsMixin(Base = class {}) {
  // @ts-ignore
  return class extends Base {
    constructor(props = new Base()) {
      super();
      this.setStateSubj = new Subject();
      this.setState = val => this.setStateSubj.next(val);
      this.setStateEvent = new ReplaySubject(1);
      // @ts-ignore
      let origProps = Object.entries(props).filter(([key, val]) => !key.startsWith('prop'));
      Object.assign(this, Object.fromEntries(origProps));
    }
  };
}
class WMLComponentBaseZeroProps extends WMLComponentBaseZeroPropsMixin() {}
/**
 * @ meant to be a base or abstract class use directily if you know what you are doing
*/
// TODO A
class WMLComponentBaseZero {
  constructor(props) {
    this.ngUnsub = new Subject();
    this.listenForSetState = () => {
      // @ts-ignore
      return this.props?.setStateSubj.pipe(takeUntil(this.ngUnsub), tap(res => {
        // @ts-ignore
        Object.assign(this.props, res);
        this.cdref?.detectChanges();
        this.props.setStateEvent.next(this.props);
      }));
    };
    if (props?.classPrefix !== undefined) {
      this.classPrefix = generateClassPrefix(props.classPrefix);
      this.myClass = this.classPrefix(`View`);
    }
    if (props?.idPrefix !== undefined) {
      this.idPrefix = generateIdPrefix(props.idPrefix);
      this.myId = this.idPrefix();
    }
  }
  ngOnDestroy() {
    // @ts-ignore
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
  static {
    this.ɵfac = function WMLComponentBaseZero_Factory(__ngFactoryType__) {
      i0.ɵɵinvalidFactory();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WMLComponentBaseZero,
      hostVars: 3,
      hostBindings: function WMLComponentBaseZero_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("id", ctx.myId);
          i0.ɵɵclassMap(ctx.myClass);
        }
      },
      inputs: {
        props: "props"
      }
    });
  }
}
__decorate([NotifyOnChange()], WMLComponentBaseZero.prototype, "props", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WMLComponentBaseZero, [{
    type: Directive
  }], () => [{
    type: undefined
  }], {
    props: [{
      type: Input,
      args: ["props"]
    }],
    myClass: [{
      type: HostBinding,
      args: ['class']
    }],
    myId: [{
      type: HostBinding,
      args: ['attr.id']
    }]
  });
})();

/*
 * Public API Surface of wml-components-base
 */

/**
 * Generated bundle index. Do not edit.
 */

export { WMLAngularCustomComponent, WMLAngularModuleForRootProps, WMLAngularMotionUIProperty, WMLAngularTestHttpHandler, WMLAngularTestUtils, WMLComponentBaseZero, WMLComponentBaseZeroProps, WMLComponentBaseZeroPropsMixin, WMLNGXTranslateLoader, WMLNGXTranslateMockPipe, WMLNGXTranslatePipe, addCustomComponent, createBasicObservableError };
