import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, PLATFORM_ID, forwardRef, Component, Inject, Input, ViewChild, Output, Directive, HostListener, Injectable, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
const _c0 = ["captcha"];
const CAPTCHA_CONFIG = new InjectionToken('CAPTCHA_CONFIG');

/**
 * Load the hCaptcha script by appending a script element to the head element.
 * The script won't be loaded again if it has already been loaded.
 * Async and defer are set to prevent blocking the renderer while loading hCaptcha.
 */
function loadHCaptcha(languageCode) {
  return new Observable(observer => {
    // No window object (ssr)
    if (!window) {
      return;
    }
    // The hCaptcha script has already been loaded
    if (typeof window.hcaptcha !== 'undefined') {
      observer.next();
      observer.complete();
      return;
    }
    let src = 'https://hcaptcha.com/1/api.js?render=explicit';
    // Set language code
    if (languageCode) {
      src += `&hl=${languageCode}`;
    }
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onerror = e => observer.error(e);
    script.onload = () => {
      observer.next();
      observer.complete();
    };
    document.head.appendChild(script);
  });
}
class NgHcaptchaComponent {
  constructor(config, zone, platformId) {
    this.config = config;
    this.zone = zone;
    this.platformId = platformId;
    this.verify = new EventEmitter();
    this.expired = new EventEmitter();
    this.error = new EventEmitter();
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  // Initialization
  ngOnInit() {
    // Use language code from module config when input parameter is not set
    if (!this.languageCode) {
      this.languageCode = this.config.languageCode;
    }
    // Do not load hCaptcha if platform is server
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.captcha$ = loadHCaptcha(this.languageCode).subscribe(() => {
      setTimeout(context => {
        // Configure hCaptcha
        const options = {
          sitekey: context.siteKey || context.config.siteKey,
          theme: context.theme,
          size: context.size,
          tabindex: context.tabIndex,
          callback: res => {
            context.zone.run(() => context.onVerify(res));
          },
          'expired-callback': res => {
            context.zone.run(() => context.onExpired(res));
          },
          'error-callback': err => {
            context.zone.run(() => context.onError(err));
          }
        };
        // Render hCaptcha using the defined options
        context.widgetId = window.hcaptcha.render(context.captcha.nativeElement, options);
      }, 50, this);
    }, error => {
      console.error('Failed to load hCaptcha script', error);
    });
  }
  ngOnDestroy() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.captcha$.unsubscribe();
  }
  // ControlValueAccessor implementation
  writeValue(value) {
    // Needs to be implemented to make the FormGroup's reset function work
    this.value = value;
    // Reset hCaptcha.
    // We need to check whether window.hcaptcha is defined because
    // writeValue(value: any) can be called before hCaptcha has been intialized.
    if (isPlatformBrowser(this.platformId) && !this.value && window.hcaptcha) {
      window.hcaptcha.reset(this.widgetId);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  reset() {
    window.hcaptcha.reset(this.widgetId);
  }
  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }
  // Internal functions
  /**
   * Is called when the verification was successful
   * @param response The verification token
   */
  onVerify(response) {
    this.value = response;
    this.verify.emit(response);
  }
  /**
   * Is called when the verification has expired
   * @param response The verification response
   */
  onExpired(response) {
    this.expired.emit(response);
  }
  /**
   * Is called when an error occurs during the verification process
   * @param error The error returned by hCaptcha
   */
  onError(error) {
    this.error.emit(error);
  }
  static {
    this.ɵfac = function NgHcaptchaComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgHcaptchaComponent)(i0.ɵɵdirectiveInject(CAPTCHA_CONFIG), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgHcaptchaComponent,
      selectors: [["ng-hcaptcha"]],
      viewQuery: function NgHcaptchaComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.captcha = _t.first);
        }
      },
      inputs: {
        siteKey: "siteKey",
        theme: "theme",
        size: "size",
        tabIndex: "tabIndex",
        languageCode: "languageCode"
      },
      outputs: {
        verify: "verify",
        expired: "expired",
        error: "error"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NgHcaptchaComponent),
        multi: true
      }])],
      decls: 2,
      vars: 0,
      consts: [["captcha", ""], [1, "h-captcha"]],
      template: function NgHcaptchaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 1, 0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgHcaptchaComponent, [{
    type: Component,
    args: [{
      selector: 'ng-hcaptcha',
      template: '<div #captcha class="h-captcha"></div>',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NgHcaptchaComponent),
        multi: true
      }]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CAPTCHA_CONFIG]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    siteKey: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    tabIndex: [{
      type: Input
    }],
    languageCode: [{
      type: Input
    }],
    captcha: [{
      type: ViewChild,
      args: ['captcha', {
        static: true
      }]
    }],
    verify: [{
      type: Output
    }],
    expired: [{
      type: Output
    }],
    error: [{
      type: Output
    }]
  });
})();
class NgHcaptchaInvisibleButtonDirective {
  constructor(elRef, config, zone, platformId) {
    this.elRef = elRef;
    this.config = config;
    this.zone = zone;
    this.platformId = platformId;
    this.verify = new EventEmitter();
    this.expired = new EventEmitter();
    this.error = new EventEmitter();
    this.click = new EventEmitter();
  }
  ngOnInit() {
    // Use language code from module config when input parameter is not set
    if (!this.languageCode) {
      this.languageCode = this.config.languageCode;
    }
    // Do not load hCaptcha if platform is server
    if (isPlatformServer(this.platformId)) {
      return;
    }
    // Load the hCaptcha script
    this.captcha$ = loadHCaptcha(this.languageCode).subscribe(() => {
      setTimeout(context => {
        // Configure hCaptcha
        const options = {
          sitekey: context.siteKey || context.config.siteKey,
          size: 'invisible',
          callback: res => {
            context.zone.run(() => context.onVerify(res));
          },
          'expired-callback': res => {
            context.zone.run(() => context.onExpired(res));
          },
          'error-callback': err => {
            context.zone.run(() => context.onError(err));
          }
        };
        // Render hCaptcha using the defined options
        context.widgetId = window.hcaptcha.render(context.elRef.nativeElement, options);
      }, 50, this);
    });
  }
  ngOnDestroy() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.captcha$.unsubscribe();
  }
  onClick(event) {
    if (event.hCaptchaToken) {
      return;
    }
    this.lastClickEvent = event;
    event.stopPropagation();
    event.preventDefault();
    event.cancelBubble = true;
    event.stopImmediatePropagation();
    // Only execute hCaptcha if platform is browser
    if (isPlatformBrowser(this.platformId)) {
      window.hcaptcha.execute(this.widgetId);
    }
    return false;
  }
  reset() {
    window.hcaptcha.reset(this.widgetId);
  }
  /**
   * Is called when the verification was successful
   * @param response The verification token
   */
  onVerify(response) {
    const event = this.lastClickEvent || {};
    event.hCaptchaToken = response;
    this.click.emit(event);
    this.verify.emit(response);
  }
  /**
   * Is called when the verification has expired
   * @param response The verification response
   */
  onExpired(response) {
    this.expired.emit(response);
  }
  /**
   * Is called when an error occurs during the verification process
   * @param error The error returned by hCaptcha
   */
  onError(error) {
    this.error.emit(error);
  }
  static {
    this.ɵfac = function NgHcaptchaInvisibleButtonDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgHcaptchaInvisibleButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(CAPTCHA_CONFIG), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgHcaptchaInvisibleButtonDirective,
      selectors: [["", "ngHcaptchaInvisibleButton", ""]],
      hostBindings: function NgHcaptchaInvisibleButtonDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function NgHcaptchaInvisibleButtonDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
      },
      inputs: {
        siteKey: "siteKey",
        languageCode: "languageCode"
      },
      outputs: {
        verify: "verify",
        expired: "expired",
        error: "error",
        click: "click"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgHcaptchaInvisibleButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[ngHcaptchaInvisibleButton]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CAPTCHA_CONFIG]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    siteKey: [{
      type: Input
    }],
    languageCode: [{
      type: Input
    }],
    verify: [{
      type: Output
    }],
    expired: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    click: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class NgHcaptchaService {
  constructor(captchaConfig, zone) {
    this.captchaConfig = captchaConfig;
    this.zone = zone;
  }
  verify() {
    return new Observable(subscriber => {
      loadHCaptcha(this.captchaConfig.languageCode).subscribe(() => {
        setTimeout(context => {
          // Create hCaptcha element
          if (!this.hCaptchaElement) {
            this.hCaptchaElement = document.createElement('div');
            document.body.appendChild(this.hCaptchaElement);
          }
          // Render hCaptcha using the defined options
          if (!this.hCaptchaWidgetId) {
            // Configure hCaptcha
            const options = {
              sitekey: this.captchaConfig.siteKey,
              size: 'invisible',
              callback: res => {
                this.zone.run(() => {
                  subscriber.next(res);
                  subscriber.complete();
                  this.resetHcaptcha();
                });
              },
              'expired-callback': res => {
                this.zone.run(() => {
                  subscriber.error(res);
                  this.resetHcaptcha();
                });
              },
              'error-callback': err => {
                this.zone.run(() => {
                  subscriber.error(err);
                  this.resetHcaptcha();
                });
              }
            };
            this.hCaptchaWidgetId = window.hcaptcha.render(this.hCaptchaElement, options);
          }
          // Immediately execute hCaptcha
          window.hcaptcha.execute(this.hCaptchaWidgetId);
        }, 50, this);
      });
    });
  }
  resetHcaptcha() {
    window.hcaptcha.remove(this.hCaptchaWidgetId);
    this.hCaptchaElement = null;
    this.hCaptchaWidgetId = null;
  }
  static {
    this.ɵfac = function NgHcaptchaService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgHcaptchaService)(i0.ɵɵinject(CAPTCHA_CONFIG), i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgHcaptchaService,
      factory: NgHcaptchaService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgHcaptchaService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CAPTCHA_CONFIG]
    }]
  }, {
    type: i0.NgZone
  }], null);
})();
class NgHcaptchaModule {
  static forRoot(config) {
    return {
      ngModule: NgHcaptchaModule,
      providers: [NgHcaptchaService, {
        provide: CAPTCHA_CONFIG,
        useValue: config || []
      }]
    };
  }
  static {
    this.ɵfac = function NgHcaptchaModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgHcaptchaModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgHcaptchaModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgHcaptchaModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [NgHcaptchaComponent, NgHcaptchaInvisibleButtonDirective],
      exports: [NgHcaptchaComponent, NgHcaptchaInvisibleButtonDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-hcaptcha
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CAPTCHA_CONFIG, NgHcaptchaComponent, NgHcaptchaInvisibleButtonDirective, NgHcaptchaModule, NgHcaptchaService, loadHCaptcha };
